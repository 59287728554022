import { Typography } from '@mui/material';
import { Property } from 'api/v3/aas_core_meta/types';
import { messages } from 'i18n/localization';
import { FormattedMessage } from 'react-intl';

type PropertyComponentProps = {
    readonly property: Property;
};

export function PropertyComponent(props: PropertyComponentProps) {
    const { property } = props;
    if (property && property.value && (property.value === 'true' || property.value === 'false')) {
        return (
            <Typography>
                <FormattedMessage {...messages.mnestix.boolean[property.value]} />
            </Typography>
        );
    } else {
        return (
            <Typography>
                {property.value?.toString() || <FormattedMessage {...messages.mnestix.notAvailable} />}
            </Typography>
        );
    }
}
