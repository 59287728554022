import { Submodel } from 'api/v3/aas_core_meta/types';
import { encodeBase64 } from 'util/Base64Util';

export class ConfigurationShellApi {
    basePath: string;
    private readonly useAuthentication = window._env_.REACT_APP_USE_AUTHENTICATION_FEATURE_FLAG;
    
    constructor(protected _basePath: string = '') {
        this.basePath = _basePath;
    }

    public async getIdGenerationSettings(bearerToken: string): Promise<Submodel> {
        const submodelReferences = await this.getSubmodelReference(bearerToken);
        const submodelIdEncoded = encodeBase64(submodelReferences.result[0].keys[0]?.value);
        
        let headers = {
            Accept: 'application/json'
        }
        if (this.useAuthentication) {
            headers['Authorization'] = bearerToken;
        }
        
        const response = await fetch('submodel/default/' + submodelIdEncoded, {
            method: 'GET',
            headers
        })
        
        if (response.status >= 200 && response.status < 300) {
            return response.json();
        } else {
            throw response;
        }
    }
    
    private async getSubmodelReference(bearerToken: string): Promise<any> {
        
        let headers = {
            Accept: 'application/json'
        }
        if (this.useAuthentication) {
            headers['Authorization'] = bearerToken;
        }
        
        const response = await fetch(this.basePath, {
            method: 'GET',
            headers
        });

        if (response.status >= 200 && response.status < 300) {
            return response.json();
        } else {
            throw response;
        }
    }

    public async putSingleIdGenerationSetting(idShort: string, bearerToken: string, values: {
        prefix: string,
        dynamicPart: string
    }) {
        await this.putSingleIdGenerationSettingValue(`${idShort}.Prefix`, bearerToken, values.prefix);
        await this.putSingleIdGenerationSettingValue(`${idShort}.DynamicPart`, bearerToken, values.dynamicPart);
    }

    protected async putSingleIdGenerationSettingValue(path: string, bearerToken: string, value: string): Promise<void | Response> {
        
        let headers = {
            'Content-Type': 'application/json',
        }
        if (this.useAuthentication) {
            headers['Authorization'] = bearerToken;
        }
        
        const response = await fetch('configuration/value/' + path, {
            method: 'PATCH',
            headers,
            body: '"' + value + '"'
        });

        if (response.status >= 200 && response.status < 300) {
            return response;
        } else {
            throw response;
        }
    }
}
