import { Box } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import { ViewHeading } from '../basics/ViewHeading';
import { messages } from 'i18n/localization';
import { useState } from 'react';
import { IdGenerationSettingFrontend } from 'types/IdGenerationSettingFrontend';
import { getArrayFromString } from 'util/SubmodelResolverUtil';
import { IdSettingsCard } from 'components/composits/settings/id-settings/IdSettingsCard';
import { useNotificationSpawner } from 'hooks/UseNotificationSpawner';
import { ISubmodelElement, Property, Qualifier, SubmodelElementCollection } from 'api/v3/aas_core_meta/types';
import { configurationClient } from 'api';
import { showError } from 'util/ErrorHandlerUtil';
import { useAsyncEffect } from 'hooks/UseAsyncEffect';
import { useAuth } from 'hooks/UseAuth';

export function RepositorySettingsView() {
    const notificationSpawner = useNotificationSpawner();
    const intl = useIntl();
    const [settings, setSettings] = useState<IdGenerationSettingFrontend[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    const auth = useAuth();
    const bearerToken = auth.getBearerToken();

    const fetchSettings = async () => {
        try {
            setIsLoading(true);
            const res = await configurationClient.getIdGenerationSettings(bearerToken);
            const _settings: IdGenerationSettingFrontend[] = [];
            // set settings from api response
            res.submodelElements?.forEach((el) => {
                const element = el as ISubmodelElement;
                const collection = el as SubmodelElementCollection;
                const _settingsList = collection.value;
                const name = el.idShort;

                // IdType (to apply correct validation)
                const idTypeQualifier = element.qualifiers?.find((q: Qualifier) => {
                    return q.type === 'SMT/IdType';
                });
                const idType = idTypeQualifier?.value;

                const prefix = _settingsList?.find((e) => e.idShort === 'Prefix') as Property;
                const dynamicPart = _settingsList?.find((e) => e.idShort === 'DynamicPart') as Property;

                const dynamicPartAllowedQualifier = dynamicPart?.qualifiers?.find((q: Qualifier) => {
                    return q.type === 'AllowedValue';
                });
                const allowedDynamicPartValues = getArrayFromString(dynamicPartAllowedQualifier?.value || '');

                const prefixExampleValueQualifier = prefix?.qualifiers?.find((q: Qualifier) => {
                    return q.type === 'ExampleValue';
                });
                const prefixExampleValue = prefixExampleValueQualifier?.value;

                _settings.push({
                    name: name || '',
                    idType,
                    prefix: {
                        value: prefix?.value,
                        exampleValue: prefixExampleValue,
                    },
                    dynamicPart: {
                        value: dynamicPart?.value,
                        allowedValues: allowedDynamicPartValues,
                        // (we do not fill example value from api currently)
                    },
                });
            });
            setSettings(_settings);
        } catch (e) {
            showError(e, notificationSpawner);
        } finally {
            setIsLoading(false);
        }
    };

    async function handleChange(idShort: string, values: { prefix: string; dynamicPart: string }) {
        try {
            setIsLoading(true);
            await configurationClient.putSingleIdGenerationSetting(idShort, bearerToken, values);
            await fetchSettings();
            notificationSpawner.spawn({
                message: intl.formatMessage(messages.mnestix.successfullyUpdated),
                severity: 'success',
            });
        } catch (e) {
            showError(e, notificationSpawner);
        } finally {
            setIsLoading(false);
        }
    }

    // Fetch settings initially
    useAsyncEffect(async () => {
        await fetchSettings();
    }, [bearerToken]);

    return (
        <Box sx={{ p: 3, maxWidth: '1125px', width: '100%', margin: '0 auto' }}>
            <Box sx={{ mb: 3 }}>
                <ViewHeading title={<FormattedMessage {...messages.mnestix.settings} />} />
                {/* TODO: Place action buttons here (e.g. specification button) */}
            </Box>
            <Box sx={{ mb: 3 }}>
                <IdSettingsCard idSettings={settings} isLoading={isLoading} handleChange={handleChange} />
            </Box>
        </Box>
    );
}
