import { OpenInNew } from '@mui/icons-material';
import { Box, Divider, Link, Typography } from '@mui/material';
import { ManualAASViewerInput } from 'components/composits/aas-viewer/ManualAasViewerInput';
import { FormattedMessage } from 'react-intl';
import { messages } from '../../i18n/localization';
import { useIsMobile } from 'hooks/UseBreakpoints';
import { ReactComponent as ScannerLogo } from 'assets/ScannerLogo.svg';

export function Home() {
    const isMobile = useIsMobile();
    return (
        <Box sx={{ p: 2, m: 'auto' }}>
            <Typography variant="h1" color="primary" align="center" sx={{ mt: 2 }}>
                <FormattedMessage {...messages.mnestix.welcome} />
            </Typography>
            <Typography variant="h3" align="center">
                <FormattedMessage {...messages.mnestix.digitalTwinMadeEasy} />
            </Typography>
            <Divider sx={{ my: 2 }}/>
            {!isMobile && (
                <Box>
                <Typography color="text.secondary" textAlign="center">
                    <FormattedMessage {...messages.mnestix.scanAasId} />
                </Typography>
                <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: -40 }}>
                    <ScannerLogo/>
                </Box>
                <Divider sx={{ mb: 2 }}/>
                <Typography color="text.secondary" textAlign="center" sx={{ mb: 2 }}>
                    <FormattedMessage {...messages.mnestix.orEnterManual} />:
                </Typography>
                </Box>
            )}
            <ManualAASViewerInput/>
            <Typography align="center" sx={{ mt: 4 }}>
                <FormattedMessage {...messages.mnestix.findOutMore} />:
            </Typography>
            <Typography align="center">
                <Link
                    href="https://mnestix.io"
                    target="_blank"
                    sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                >
                    <span>mnestix.io</span>
                    <OpenInNew fontSize="small"/>
                </Link>
            </Typography>
        </Box>
    );
}
